<template>
    <div>
        <fromSearch :object_='seriesList_' @searchFun='getList' ref='fromSearch_'  />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button type="primary" @click="downBlobFileFun(fromSearch_.returnParm(),page)">导出</el-button>
            </template>
            <template #withdrawTime="scope">
                {{turnTimeFun(scope.row.withdrawTime)}}
            </template>
            <template #applyTime="scope">
                {{turnTimeFun(scope.row.applyTime)}}
            </template>
            <template #callbackTime="scope">
                {{turnTimeFun(scope.row.callbackTime)}}
            </template>
            <template #menu="scope">
                <el-button text type="primary" size="small" @click="callbackFun(scope.row)">回调</el-button>
            </template> 
        </avue-crud>   
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import { withdrawCash_ } from '@/const/from/record/customerWallet.js'
    import fromSearch from '@/components/fromSearch.vue'
    import { withdrawCash } from '@/const/crud/record/customerWallet'
    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['pageByMerUser'])
    import { turnTimeFun } from '@/utils/util.js'
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    
    import mixins1 from '@/views/mixins/common'
    let commonFun = mixins1('withdrawCash_');const { downBlobFileFun } = commonFun
    const fromSearch_ = ref(null)

    const tableOption = ref({}); tableOption.value = withdrawCash
    const seriesList_ = ref(withdrawCash_);
    getList(1)
    const callbackFun = (scope)=>{
        listLoading.value = true
        Api_.pageByMerUserCall(scope.applyId).then(res=>{
            listLoading.value = false
            if(res.code === 0){
                ElNotification.success('回调成功')
            }else{
                ElNotification.error(res.msg)
            }
        })
    }
</script>

<style lang="scss" scoped>
    :deep(.el-dialog__header){
        margin-bottom: 0;
    }
    :deep(.el-dialog__body){
        padding-top: 10px;
    }
</style>